import { SubscriptionEvents as BackendSubscriptionEvents } from '~/enums/backend-events.enums';
import { SubscriptionEvents as TrackingSubscriptionEvents } from '~/enums/tracking/tracking-events.enums';

export default defineNuxtPlugin({
  dependsOn: ['echo', 'gtm'],
  setup: () => {

    const { $gtmPush, $privateUserChannel } = useNuxtApp();

    const sendSubscriptionEvent = (backendEvent: string, eventData: any) => {
      const eventMap = {
        [BackendSubscriptionEvents.SubscriptionPurchased]: TrackingSubscriptionEvents.SubscriptionPurchased,
        [BackendSubscriptionEvents.SubscriptionCancelled]: TrackingSubscriptionEvents.SubscriptionCancelled,
        [BackendSubscriptionEvents.SubscriptionReactivated]: TrackingSubscriptionEvents.SubscriptionReactivated,
        [BackendSubscriptionEvents.SubscriptionUpgraded]: TrackingSubscriptionEvents.SubscriptionUpgraded,
      } as {[key: string]: string};

      if (!(backendEvent in eventMap)) {
        console.warn(`Could not resolve backend event name: ${backendEvent}`, eventData);
        return;
      }

      const gtmEventBody = {
        event: eventMap[backendEvent],
        plan_type: eventData.plan.type,
        plan_price: eventData.plan.price,
        currency: eventData.plan.currency,
        subscription_date: eventData.subscription.term_start,
      } as any;

      if (backendEvent === BackendSubscriptionEvents.SubscriptionCancelled) {
        gtmEventBody['old_plan_type'] = eventData.previous_plan.type;
      }

      $gtmPush(gtmEventBody);
    };

    const startListener = () => {
      if (!$privateUserChannel.value) {
        return;
      }

      const channel = $privateUserChannel.value;

      channel.listen(BackendSubscriptionEvents.SubscriptionPurchased, (event: any) => {
        sendSubscriptionEvent(BackendSubscriptionEvents.SubscriptionPurchased, event);
      });

      channel.listen(BackendSubscriptionEvents.SubscriptionCancelled, (event: any) => {
        sendSubscriptionEvent(BackendSubscriptionEvents.SubscriptionCancelled, event);
      });

      channel.listen(BackendSubscriptionEvents.SubscriptionReactivated, (event: any) => {
        sendSubscriptionEvent(BackendSubscriptionEvents.SubscriptionReactivated, event);
      });

      channel.listen(BackendSubscriptionEvents.SubscriptionUpgraded, (event: any) => {
        sendSubscriptionEvent(BackendSubscriptionEvents.SubscriptionUpgraded, event);
      });
    };

    watch($privateUserChannel, () => {
      startListener();
    }, { immediate: true });
  },
});