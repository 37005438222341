import type { FetchContext } from 'ofetch';

const CSRF_COOKIE = 'XSRF-TOKEN';
const CSRF_HEADER = 'X-XSRF-TOKEN';

const fetchOnRequestInterceptor = async ({ options }: FetchContext) => {
  let token = useCookie(CSRF_COOKIE).value;

  if (['post', 'delete', 'put', 'patch'].includes(options?.method?.toLowerCase() ?? '')) {
    token = await initCsrf();
  }

  options.credentials = 'include';
  options.headers = {
    accept: 'application/json',
    ...options?.headers,
    ...(token && { [CSRF_HEADER]: token }),
  };
};

const resetCsrf = async () => {
  useCookie(CSRF_COOKIE).value = '';
  await nextTick();
};

async function fetchCsrf () {
  const config = useRuntimeConfig();
  await $fetch(config.public.proxyPrefix + '/auth/csrf-cookie', {
    credentials: 'include',
  });
}

async function initCsrf () {
  const config = useRuntimeConfig();
  const existingToken = useCookie(CSRF_COOKIE).value;

  if (existingToken) {
    return existingToken;
  }

  await $fetch(config.public.proxyPrefix + '/auth/csrf-cookie', {
    credentials: 'include',
  });

  return useCookie(CSRF_COOKIE).value;
}

export default {
  fetchOnRequestInterceptor,
  resetCsrf,
  fetchCsrf,
};